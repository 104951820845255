.div-check{
    width: 900px;
    height: 500px;
    margin-left: 13%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.div-check .div-icon{
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
}

.div-check .icon{
    width: 150px;
    height: 100px;
    display: block;
    color:rgb(45, 167, 45);
}

.div-check .div-p{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.div-check .div-p p{
    color: rgb(45, 167, 45);
    font-size: 30px;
    margin-top: 10px;
}

.div-check .div-p h5{
    font-size: 12px;
}

.div-check .div-protocol{
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;   
}

.div-check .div-protocol .protocol-bar{
    border: 4px solid rgb(45, 167, 45);
    border-radius: 10px;
    width: 250px;
    height: 100px;
    padding: 10px;
    background-color: rgba(199, 253, 199, 0.233);
}

.div-check .div-protocol .protocol-bar p{
    font-size: 13px;
    color: #000;
}

.div-check .div-protocol .protocol-bar h1{
    color: green;
}

.btn{
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(45, 167, 45);
    color: #fff;
    margin-top: 10px;
    border: none;
    align-items: center;
    margin-left: 800px;
    cursor: pointer;
    transition: all 0.3s;
}

.btn:hover{
    background-color: rgb(57, 190, 57);
    color: #000;
}