.div-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 50px;
    flex-wrap: wrap;
}
.div-form h1{
    font-size: 30px;
    text-align: center;
    font-style: normal;
}

.div-form p{
    font-size: 20px;
    font-style: normal;
    margin-left: 50px;
}


.div-unit {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.div-unit :last-child{
    margin-right: 50px;
}

.div-form label{
    font-size: 15px;
}

.div-button{
    margin-top:15px;
    margin-right: 50px;
    margin-bottom: 10px;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}