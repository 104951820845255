input[type="file"]{
    display: none;
}

.up{
    border: 1px solid rgb(36, 123, 204);
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    background-color:rgb(36, 123, 204);
    color: #fff;
    transition: all 0.2s;
}

.up:hover{
    background-color: rgb(2, 127, 243);
    color: #fff;
}