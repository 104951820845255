.table{
    border-collapse: collapse;
    width: 100%;
    display: flex;
}

.table td{
    border: 1px solid #ddd;
    text-align:center;
    padding: 8px;
}

tr:nth-child(even){
    background-color: #ddd;
}