.div-text{
    margin-left: 25px;
}

.box-protocol{
    border: 4px solid rgb(74, 187, 51);
    background-color: rgba(161, 241, 145, 0.527);
    border-radius: 8px;
    width: 300px;
    height: 200px;
    margin: 10px 500px 10px 500px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: center;
}

.box-protocol p{
    font-size: 14px;
    color: rgb(69, 153, 31);
}

.box-protocol h1{
    color: rgb(52, 112, 24);
}

.btn-div{
    margin-left: 1000px;
    margin-top: 70px;
    margin-bottom: 30px;
}