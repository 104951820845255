@import url('https://fonts.googleapis.com/css2?family=Sanchez&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.MuiTypography-h2 {
  font-family: 'Sanchez', 'Roboto', serif;
}
h1,
h2,
h3,
h5 {
  font-family: 'Sanchez', serif;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}
.app-container {
  background-color: #f7f7f9;
  height: 100vh;
  width: 100vw;
}
