.button{
    width: 100px;
    height: 40px;
    background-color: #198cce;
    margin-left: 5px;
    color: #fff;
    transition: background-color(1s);
    cursor: pointer;
    font-weight: bold;
}

.button:hover{
    background-color: #1c7cb4;
}